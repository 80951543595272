import { render, staticRenderFns } from "./AuthCard.vue?vue&type=template&id=96d0b588&"
import script from "./AuthCard.vue?vue&type=script&lang=js&"
export * from "./AuthCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports